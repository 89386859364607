import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/home';
import SafeWork from './pages/safe-work';
import WorkGuide from './pages/work-guide';
import Factors from './pages/factors';
import DownloadResults from './pages/download-results';
import TechnicalStandards from './pages/technical-standards';
import Standard from './pages/standard';
import Form from './pages/form';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/home' element={<Home />} />
      <Route path='/standard' element={<Standard />} />
      <Route path='/standard/:id' element={<Standard />} />
      <Route path='/form/:id' element={<Form />} />
      <Route path='safe-work' element={<SafeWork />} />
      <Route path='work-guide' element={<WorkGuide />} />
      <Route path='factors' element={<Factors />} />
      <Route path='technical-standards' element={<TechnicalStandards />} />
      <Route path='download-results' element={<DownloadResults />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
