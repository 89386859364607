import '../App.css';
import { Table } from 'antd';
import React from 'react';
import axios from 'axios';
import { Excel } from 'antd-table-saveas-excel';
import logoInteco from '../assets/image/logo-inteco.png';

const baseURL = 'https://inteco-download-standards.herokuapp.com/api/downloads';
const columns = [
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Primer Apellido',
    dataIndex: 'latName',
    key: 'latName'
  },
  {
    title: 'Segundo Apellido',
    dataIndex: 'secondLastName',
    key: 'secondLastName'
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Empresa',
    dataIndex: 'company',
    key: 'company'
  },
  {
    title: 'Norma',
    dataIndex: 'standard',
    key: 'standard'
  }
];

function DownloadResults() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadResults() {
      try {
        const result = await axios.get(baseURL);

        if (result && result.status === 200) {
          setData(
            result.data.data.map((row) => ({
              ...row,
              key: row.id,
              date: new Date(row.date).toLocaleDateString('es-CR')
            }))
          );
        }

        setLoading(false);
      } catch (error) {
        console.log('🚀 loadResults ~ error', error);
        setLoading(false);
      }
    }

    if (loading) {
      loadResults();
    }
  }, [loading]);

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet('test')
      .addColumns(columns)
      .addDataSource(data, {
        str2Percent: true
      })
      .saveAs('Excel.xlsx');
  };

  return (
    <div>
      <div className='header' style={{ padding: 10 }}>
        <div style={{ width: 900 }}>
          <img src={logoInteco} alt='logo' style={{ width: 200 }} />
        </div>
      </div>
      <div className='app-result-container'>
        {loading && <p>Cargando...</p>}
        <button onClick={handleClick}>Exportar a excel</button>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
}

export default DownloadResults;
