import '../App.css';
import React from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import logoInteco from '../assets/image/logo-inteco.png';
import logoCso from '../assets/image/logo-cso.jpg';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 16
  }
};
/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!'
  },
  number: {
    range: '${label} must be between ${min} and ${max}'
  }
};
/* eslint-enable no-template-curly-in-string */

const baseURL = 'https://inteco-download-standards.herokuapp.com/standard';
const antIcon = (
  <>
    <LoadingOutlined style={{ fontSize: 32 }} spin />
    <p style={{ fontSize: 14 }}>Procesando solicitud...</p>
  </>
);

function SafeWork() {
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await axios.post(baseURL, { ...values });
    console.log(result);
    setLoading(false);
  };
  return (
    <div>
      <div className='header' style={{ padding: 10 }}>
        <div style={{ width: 900 }}>
          <img src={logoInteco} alt='logo' style={{ width: 200 }} />
        </div>
      </div>

      <div className='contact-form'>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin indicator={antIcon} />
          </div>
        ) : (
          <>
            <div style={{ padding: 20 }}>
              <h2
                style={{
                  marginBottom: '22px',
                  color: '#00A0E3',
                  fontWeight: 300,
                  lineHeight: '1.1',
                  fontSize: '1.875rem'
                }}
              >
                Trabajo seguro durante la pandemia
              </h2>
              <div className='logoCso'>
                <img src={logoCso} alt='logo' style={{ width: '230px' }} />{' '}
                <img
                  src={logoInteco}
                  alt='logo'
                  style={{ width: '400px', marginLeft: 15, float: 'right' }}
                />
              </div>
              <p style={{ fontSize: '0.80rem', marginTop: 20 }}>
                Aportando a la calidad de vida de las personas trabajadoras.
              </p>
              <p style={{ fontSize: '0.80rem' }}>
                Llene sus datos y recibirá un correo electrónico con la norma
                INTE/ISO/PAS 45005:2021
              </p>
            </div>
            <Form
              {...layout}
              name='nest-messages'
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={['user', 'name']}
                label='Nombre'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'firstName']}
                label='Primer Apellido'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'lastName']}
                label='Segundo Apellido'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'phone']}
                label='Teléfono'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'email']}
                label='Correo electrónico'
                rules={[
                  {
                    type: 'email',
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'job']}
                label='Empresa donde labora'
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type='primary' htmlType='submit'>
                  Enviar
                </Button>
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <p style={{ color: 'gray', fontSize: 13, fontStyle: 'italic' }}>
                  Al completar el formulario usted, recibirá un correo
                  electrónico con la norma INTE ISO PAS 45005 2021
                </p>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
}

export default SafeWork;
