import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const VerticalMenu = ({ data, categoryId, handleSelectCategory }) => {
  return (
    <div>
      {data.map((category) => (
        <div
          key={category.id}
          className={
            categoryId === category.id ? 'ct-menu ct-menu-selected' : 'ct-menu'
          }
          onClick={(_) => handleSelectCategory(category.id)}
        >
          <p
            style={{
              marginBottom: '0px'
            }}
          >
            {category.name}
          </p>
        </div>
      ))}
    </div>
  );
};

VerticalMenu.defaultProps = {
  data: []
};

VerticalMenu.propTypes = {
  data: PropTypes.array,
  categoryId: PropTypes.number,
  handleSelectCategory: PropTypes.func
};

export default VerticalMenu;
