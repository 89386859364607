import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Button, Space, Table } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { baseURL } from '../utils/constants';

import VerticalMenu from '../components/vertical-menu';
import logoInteco from '../assets/image/logo-inteco.png';
const { Column } = Table;

const menuOptions = [
  { id: 1, name: 'Normas' },
  { id: 2, name: 'Descargas' }
];

const downloadColumns = [
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Nombre',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Primer Apellido',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'Segundo Apellido',
    dataIndex: 'secondLastName',
    key: 'secondLastName'
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Empresa',
    dataIndex: 'company',
    key: 'company'
  },
  {
    title: 'Norma',
    dataIndex: 'standard',
    key: 'standard'
  }
];

const home = () => {
  const navigate = useNavigate();
  const isLogged = localStorage.getItem('isLogged');
  const [selectedMenu, setSelectedMenu] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [dataStandard, setDataStandard] = React.useState([]);
  const [dataDownloads, setDataDownloads] = React.useState([]);

  React.useEffect(() => {
    const loadStandards = async () => {
      if (!isLogged) {
        navigate('/');
        return;
      }

      try {
        setLoading(true);
        const resp = await axios.get(baseURL + '/standard');

        if (resp && resp.status === 200) {
          setDataStandard(
            resp.data.data.map((row) => ({
              ...row,
              key: row.id,
              createdAt: new Date(row.createdAt).toLocaleDateString('es-CR')
            }))
          );
        }
        setLoading(false);
      } catch (error) {
        console.log('🚀 loadResults ~ error', error);
        setLoading(false);
      }
    };

    const loadDownloads = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(baseURL + '/standard/download');

        if (resp && resp.status === 200) {
          setDataDownloads(
            resp.data.data.map((row) => ({
              ...row,
              key: row.id,
              date: new Date(row.date).toLocaleDateString('es-CR')
            }))
          );
        }
        setLoading(false);
      } catch (error) {
        console.log('🚀 loadResults ~ error', error);
        setLoading(false);
      }
    };

    loadStandards();
    loadDownloads();
  }, []);

  const handleDownloadResult = () => {
    const excel = new Excel();
    excel
      .addSheet('test')
      .addColumns(downloadColumns)
      .addDataSource(dataDownloads, {
        str2Percent: true
      })
      .saveAs('Excel.xlsx');
  };

  return (
    <div>
      <div className='header' style={{ padding: 10 }}>
        <div style={{ width: '1280px', margin: 'auto', textAlign: 'initial' }}>
          <img src={logoInteco} alt='logo' style={{ width: 200 }} />
          <Link to={`/`} style={{ marginLeft: '14px' }}>
            Formularios
          </Link>
        </div>
      </div>
      <div className='container-home'>
        <Row>
          <Col
            span={25}
            style={{
              paddingTop: '10px',
              paddingBottom: '20px'
            }}
          >
            <h3>Plataforma para la descarga de normas</h3>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <VerticalMenu
              data={menuOptions}
              categoryId={selectedMenu}
              handleSelectCategory={setSelectedMenu}
            />
          </Col>
          <Col span={21} style={{ paddingLeft: '12px' }}>
            {(() => {
              switch (selectedMenu) {
                case 1:
                  return (
                    <>
                      <Row>
                        <Col span={20}>
                          <h4>Lista de normas</h4>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          <Link to='/standard'>
                            <Button type='primary'>Nueva norma</Button>
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} style={{ paddingTop: '10px' }}>
                          <Table dataSource={dataStandard}>
                            <Column
                              title='Código'
                              dataIndex='code'
                              key='code'
                              width={200}
                            />
                            <Column
                              title='Nombre'
                              dataIndex='title'
                              key='title'
                            />
                            <Column
                              title='Estado'
                              dataIndex='isEnabled'
                              key='isEnabled'
                              render={(value) => (
                                <p style={{ margin: '0px' }}>
                                  {value ? 'Activo' : 'Inactiva'}
                                </p>
                              )}
                            />
                            <Column
                              title='Creada el'
                              dataIndex='createdAt'
                              key='createdAt'
                            />

                            <Column
                              title='Opciones'
                              key='action'
                              render={(_, record) => (
                                <Space
                                  size='middle'
                                  style={{ textAlign: 'center' }}
                                >
                                  <Link to={`/standard/${record.id}`}>Ver</Link>
                                </Space>
                              )}
                            />
                          </Table>
                          {loading && <p>Cargando...</p>}
                        </Col>
                      </Row>
                    </>
                  );
                case 2:
                  return (
                    <>
                      <Row>
                        <Col span={20}>
                          <h4>Lista de usuarios</h4>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }}>
                          <Button type='primary' onClick={handleDownloadResult}>
                            Exportar a excel
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} style={{ paddingTop: '10px' }}>
                          <Table
                            columns={downloadColumns}
                            dataSource={dataDownloads}
                          />
                          {loading && <p>Cargando...</p>}
                        </Col>
                      </Row>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default home;
