import './App.css';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Input, Modal } from 'antd';

import { baseURL } from './utils/constants';
import logoInteco from './assets/image/logo-inteco.png';

function App() {
  const navigate = useNavigate();
  const isLogged = localStorage.getItem('isLogged');
  const [loading, setLoading] = React.useState(true);
  const [dataStandard, setDataStandard] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    const loadStandards = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(baseURL + '/standard/download/public');

        if (resp && resp.status === 200) {
          setDataStandard(
            resp.data.data.map((row) => ({
              ...row,
              key: row.id,
              createdAt: new Date(row.createdAt).toLocaleDateString('es-CR')
            }))
          );
        }
        setLoading(false);
      } catch (error) {
        console.log('🚀 loadResults ~ error', error);
        setLoading(false);
      }
    };

    loadStandards();
  }, []);

  const handleSignIn = () => {
    if (password === 'Inteco.2028') {
      setMessage('Contraseña correcta');
      localStorage.setItem('isLogged', true);
      navigate(`/home`);
    } else {
      setMessage('Contraseña incorrecta');
    }
  };

  return (
    <div>
      <div className='header' style={{ padding: 10 }}>
        <div style={{ width: 900 }}>
          <img src={logoInteco} alt='logo' style={{ width: 200 }} />
        </div>
      </div>

      <div className='contact-form'>
        <h3>Formularios</h3>
        <br />
        <div>
          {loading && (
            <div>
              <p>Cargando</p>
            </div>
          )}
          {dataStandard &&
            dataStandard.map((standard) => (
              <Link key={standard.id} to={`/form/${standard.id}`}>
                <div
                  className='button-container button-link'
                  style={{ marginBottom: '12px' }}
                >
                  {standard.code} - {standard.title}
                </div>
              </Link>
            ))}
        </div>
        <div>
          <div
            className='button-container'
            style={{
              margin: '0px',
              textAlign: 'center',
              marginTop: '60px'
            }}
          >
            {isLogged ? (
              <Link to={'/home'}> Panel de configuración </Link>
            ) : (
              <Button type='primary' onClick={() => setIsModalOpen(true)}>
                Entrar
              </Button>
            )}
            {/* */}

            {/* </Link> */}
            <Modal
              title='Acceder a la configuración'
              visible={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              onOk={handleSignIn}
            >
              <p>Clave de acceso:</p>
              <Input
                placeholder='Escribir clave de acceso'
                value={password}
                type='password'
                onChange={(e) => {
                  setMessage('');
                  setPassword(e.target.value);
                }}
              />
              <p>{message}</p>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
