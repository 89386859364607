import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Col, Row, Input, Switch, Button, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { baseURL } from '../utils/constants';
import logoInteco from '../assets/image/logo-inteco.png';

function Standard() {
  const params = useParams();
  const navigate = useNavigate();
  const isLogged = localStorage.getItem('isLogged');
  const [code, setCode] = React.useState('');
  const [newStandard, setNewStandard] = React.useState(params && params.id);
  const [title, setTitle] = React.useState('');
  const [file, setFile] = React.useState({
    name: '',
    link: ''
  });
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const onChangeStatus = (checked) => {
    setIsEnabled(checked);
  };

  const uploadProps = {
    name: 'file',
    action: `${baseURL}/standard/${params.id}/file`,
    headers: {
      authorization: 'authorization-text'
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setFile({
          name: info.file.response.data.fileName,
          link: info.file.response.data.fileLink
        });
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  React.useEffect(() => {
    const validateLogin = () => {
      if (!isLogged) {
        navigate('/');
        return;
      }
    };

    validateLogin();
  }, []);

  React.useEffect(() => {
    const loadStandard = async () => {
      try {
        if (newStandard) {
          setLoading(true);

          const resp = await axios.get(`${baseURL}/standard/${params.id}`, {});

          if (resp && resp.status === 200) {
            setCode(resp.data.data.code);
            setIsEnabled(resp.data.data.isEnabled);
            setTitle(resp.data.data.title);
            setFile({
              name: resp.data.data.fileName,
              link: resp.data.data.fileLink
            });
          }
          setLoading(false);
        }
      } catch (error) {
        console.log('🚀 ~ error', error);
        setLoading(false);
      }
    };

    if (newStandard) {
      loadStandard();
    }
  }, [newStandard]);

  const handleCreateStandard = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(baseURL + '/standard', {
        code: code,
        title: title,
        isEnabled: isEnabled
      });

      if (resp && resp.status === 201) {
        // 👇️ navigate to /
        navigate(`/standard/${resp.data.data.id}`);
        setNewStandard(true);
      }
      setLoading(false);
    } catch (error) {
      console.log('🚀 loadResults ~ error', error);
      setLoading(false);
    }
  };

  const handleUpdateStandard = async () => {
    try {
      setLoading(true);
      const resp = await axios.put(`${baseURL}/standard/${params.id}`, {
        code: code,
        title: title,
        isEnabled: isEnabled
      });
      if (resp && resp.status === 201) {
        alert('Norma actualizada con exito');
      }
      setLoading(false);
    } catch (error) {
      console.log('🚀 loadResults ~ error', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <div className='header' style={{ padding: 10 }}>
        <div style={{ width: '1280px', margin: 'auto', textAlign: 'initial' }}>
          <img src={logoInteco} alt='logo' style={{ width: 200 }} />
        </div>
      </div>
      <div className='container-home'>
        <Row>
          <Col span={6} style={{ paddingRight: '10px' }}>
            <p>Código:</p>
            <Input
              placeholder='INTE/ISO - 123456'
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </Col>
          <Col span={17} style={{ paddingRight: '10px' }}>
            <p>Nombre:</p>
            <Input
              placeholder='Gestión de la calidad'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Col>
          <Col span={1}>
            <p>Estado:</p>
            <Switch checked={isEnabled} onChange={onChangeStatus} />
          </Col>
        </Row>
        <Row style={{ marginTop: '14px' }}>
          <Col span={24}>
            <Button
              type='primary'
              disabled={loading}
              onClick={
                newStandard ? handleUpdateStandard : handleCreateStandard
              }
            >
              {newStandard ? 'Guardar' : 'Crear'}
            </Button>
            {'   '}
            <Link to='/home'>
              <Button disabled={loading}>Atrás</Button>
            </Link>
            {'   '}
            {newStandard && (
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} disabled={loading}>
                  Archivo
                </Button>
              </Upload>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: '14px' }}>
          <Col span={24}>
            {file && file.name && <a href={file.link}>{file.name}</a>}
          </Col>
        </Row>
        {loading && (
          <Row style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p>Cargando...</p>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

export default Standard;
